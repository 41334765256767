import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA BULAN------
    getBulan() {
        ApiService.setHeader();
        return ApiService.get("v2/master/bulan")
    },

    // -----API DATA ESELON------
    getEselon() {
        ApiService.setHeader();
        return ApiService.get("v2/master/eselon")
    },

    // -----API DATA INSTANSI------
    getInstansi() {
        ApiService.setHeader();
        return ApiService.get("v2/master/instansi")
    },

    // -----API DATA KEPEMILIKAN------
    getKepemilikan() {
        ApiService.setHeader();
        return ApiService.get("v2/master/kepemilikan")
    },

    // -----API DATA NEGARA------
    getNegara() {
        ApiService.setHeader();
        return ApiService.get("v2/master/negara")
    },
    getPhoneCode() {
        ApiService.setHeader();
        return ApiService.get("v2/master/negara/phone-code")
    },

    // -----API DATA SISTEM KANDANG------
    getSistemKandang() {
        ApiService.setHeader();
        return ApiService.get("v2/master/sistem-kandang")
    },

    // -----API DATA STATUS------
    getStatus() {
        ApiService.setHeader();
        return ApiService.get("v2/master/status")
    },

    // -----API DATA TERNAK------
    getTernakJenis(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/ternak/jenis", { params: credentials })
    },
    getTernakKomoditas() {
        ApiService.setHeader();
        return ApiService.get("v2/master/ternak/komoditas")
    },
    getTernakSex(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/ternak/sex", { params: credentials })
    },
    getTernakStrain(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/ternak/strain", { params: credentials })
    },
    getTernakTipe(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/ternak/tipe", { params: credentials })
    },

    // -----API DATA TIPE------
    getTipeMesin() {
        ApiService.setHeader();
        return ApiService.get("v2/master/tipe/mesin")
    },
    getTipeUsaha(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/tipe/usaha", { params: credentials })
    },
    getTipeUser() {
        ApiService.setHeader();
        return ApiService.get("v2/master/tipe/user")
    },

    // ------API DATA KATEHORI------
    getKategoriUsaha(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kategori/usaha", { params: credentials })
    },
    getKategoriDistribusi(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kategori/distribusi", { params: credentials })
    },
}