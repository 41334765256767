import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA PROVINSI------
    getProvinsi(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/provinsi", { params: credentials })
    },
    getProvinsiSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/provinsi/single", { params: credentials })
    },
    addProvinsi(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/provinsi", credentials)
    },
    updateProvinsi(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/provinsi", credentials)
    },
    deleteProvinsi(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/provinsi", { data: credentials })
    },

    // -----API DATA Kabupaten------
    getKabupaten(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kabupaten", { params: credentials })
    },
    getAllKabupaten(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kabupaten/all", { params: credentials })
    },
    getKabupatenSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kabupaten/single", { params: credentials })
    },
    addKabupaten(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/kabupaten", credentials)
    },
    updateKabupaten(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/kabupaten", credentials)
    },
    deleteKabupaten(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/kabupaten", { data: credentials })
    },

    // -----API DATA Kecamatan------
    getKecamatan(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kecamatan", { params: credentials })
    },
    getAllKecamatan(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kecamatan/all", { params: credentials })
    },
    getKecamatanSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/kecamatan/single", { params: credentials })
    },
    addKecamatan(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/kecamatan", credentials)
    },
    updateKecamatan(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/kecamatan", credentials)
    },
    deleteKecamatan(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/kecamatan", { data: credentials })
    },

    // -----API DATA Desa------
    getDesa(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/desa", { params: credentials })
    },
    getAllDesa(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/desa/all", { params: credentials })
    },
    getDesaSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/desa/single", { params: credentials })
    },
    addDesa(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/desa", credentials)
    },
    updateDesa(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/desa", credentials)
    },
    deleteDesa(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/desa", { data: credentials })
    },

}